<div class="postcard">
  <div class="postcontent">
    <!-- Post Content -->
    <div class="d-flex flex-row align-items-center">
      <h3>{{ post.name }}</h3>
      <mat-chip-set 
        class="chip-container flex-grow-1 position-relative overflow-hidden">
        <div class="h-100 position-absolute top-0 left-0">
          <mat-chip
            class="opacity-0"
            processdelightIntersection
            (intersecting)="hideChip(group, $event)"
            [style.backgroundColor]="group.color + ' !important'"
            *ngFor="let group of post.groups">
            <span
              [style.color]="getContrastTextColor(group.color)">{{ group.title }}
            </span>
          </mat-chip>
        </div>
        <mat-chip
          [hidden]="hiddenChips.includes(group)"
          [style.backgroundColor]="group.color + ' !important'"
          *ngFor="let group of post.groups">
          <span
            [style.color]="getContrastTextColor(group.color)">{{ group.title }}
          </span>
        </mat-chip>
      </mat-chip-set>
      <mat-chip
        *ngIf="hiddenChips.length > 0"
        class="bg-secondary-subtle"
        [matTooltip]="getHiddenChipsTooltip()"
        matTooltipClass="chip-tooltip"
        matTooltipPosition="below">
        +{{ hiddenChips.length }}
      </mat-chip>
      <button mat-button [matMenuTriggerFor]="commentMenu" class="likebtn">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu
        #commentMenu="matMenu"
        backdropClass="orderbypanel"
        class="matmenu"
      >
        <button
          mat-menu-item
          [disabled]="
            !(
              post.portalUserAuthorId === portalUserId ||
              post.groupUserAuthorId === groupUserId ||
              canEditAllPosts
            ) || deletingPost
          "
          (click)="editPost()"
        >
          {{ translations.getTranslation$("edit") | async }}
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-menu-item
          [disabled]="
            !(
              post.portalUserAuthorId === portalUserId ||
              post.groupUserAuthorId === groupUserId ||
              canDeleteAnything
            ) || deletingPost
          "
          (click)="deletePost()"
        >
          {{ translations.getTranslation$("delete") | async }}
          <mat-icon>delete</mat-icon>
        </button>
      </mat-menu>
    </div>

    <div *ngIf="post.pictureUrl" class="imgcontainer">
      <img class="postimg" [src]="post.pictureUrl" alt="Post Image" />
    </div>

    <div class="flex-grow-1 pt-2" [innerHTML]="post.description"></div>
  </div>
  <div
    class="postInfo"
    [class.isSmallView]="isSmallView"
    [class.border-bottom-0]="!canCreateComment && post.comments.length === 0"
  >
    <!-- Post Actions & Info -->

    <div class="d-flex flex-row gap-2">
      <div class="contentfooter">
        <p>{{ post.modifiedOn?.toJSDate() | date : "dd/MM/yyyy HH:mm" }}</p>
        <p class="author">
          {{ translations.getTranslation$("by") | async }} &nbsp;
          {{ post | portalUser }}
        </p>
      </div>
    </div>
    <div class="postactions">
      <div class="likediv">
        <button
          mat-button
          class="likebtn"
          [disabled]="savingLike || !canCreateComment"
          (click)="toggleLikePost()"
        >
          <mat-icon class="chaticon">
            {{ isLikedByMe(post) ? "favorite" : "favorite_outline" }}
          </mat-icon>
        </button>
        <button
          mat-button
          (click)="post.likes?.length ? openLikeList(post) : undefined"
        >
          <span class="btnpsan"> {{ getLastLikeComment(post) }} </span>
        </button>
      </div>
      <button disabled mat-button class="counterbtn">
        <mat-icon class="chaticon">chat</mat-icon>
        <span class="btnpsan"
          >{{ post.comments?.length }} &nbsp;
          {{ translations.getTranslation$("comments") | async }}</span
        >
      </button>
    </div>
  </div>

  <!-- Post Comment Section -->

  <div class="writecommentcard" *ngIf="canCreateComment">
    <input
      class="newcomment"
      [class.border-bottom-0]="post.comments.length === 0"
      [class.bottom-border-radius]="post.comments.length === 0"
      [placeholder]="translations.getTranslation('writeComment')"
      [disabled]="savingComment"
      [formControl]="newCommentText"
      (keydown.enter)="createComment()"
    />
    <mat-spinner
      *ngIf="savingComment"
      class="spinner"
      diameter="20"
    ></mat-spinner>
  </div>

  <div class="commentscard">
    <div *ngFor="let comment of post.comments" class="comment">
      <div
        *ngIf="editingComment?.id !== comment.id"
        class="d-flex flex-column flex-grow-1"
      >
        <div class="d-flex flex-row justify-content-between">
          <p>{{ comment.message }}</p>
        </div>

        <div class="contentfooter gap-3">
          <p class="m-0">
            {{ comment.modifiedOn?.toJSDate() | date : "dd/MM/yyyy HH:mm" }}
          </p>
          <p class="author">
            {{ translations.getTranslation$("by") | async }} &nbsp;
            {{ comment | portalUser }}
          </p>
        </div>
      </div>

      <div
        *ngIf="editingComment?.id === comment.id"
        class="d-flex flex-column gap-1"
        (load)="commentEditInput.focus()"
      >
        <input
          type="text"
          #commentEditInput
          [disabled]="updatingComment"
          [(ngModel)]="editingComment!.message"
          class="border-0"
        />
        <div class="d-flex flex-row gap-1 align-items-center">
          <button
            mat-stroked-button
            [disabled]="savingComment"
            (click)="saveEditComment()"
          >
            {{ translations.getTranslation$("save") | async }}
          </button>
          <button
            mat-stroked-button
            class="likebtn"
            (click)="cancelEditComment()"
          >
            <mat-icon class="chaticon">close</mat-icon>
          </button>
          <mat-spinner
            *ngIf="updatingComment"
            style="height: 20px; width: 20px"
          ></mat-spinner>
        </div>
      </div>

      <div class="commentactions">
        <div class="commentlikediv">
          <button
            mat-button
            class="likebtn"
            [disabled]="savingLike || !canCreateLike"
            (click)="toggleLikeComment(comment)"
          >
            <mat-icon class="chaticon">{{
              isLikedByMe(comment) ? "favorite" : "favorite_outline"
            }}</mat-icon>
          </button>
          <button
            mat-button
            class="likebtn"
            (click)="comment.likes?.length ? openLikeList(comment) : undefined"
          >
            <span>
              {{ comment.likes?.length }}
            </span>
          </button>
        </div>
        <button mat-button [matMenuTriggerFor]="commentMenu" class="likebtn">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #commentMenu="matMenu">
          <button
            mat-menu-item
            [disabled]="
              !(
                comment.portalUserId === portalUserId ||
                comment.groupUserId === groupUserId ||
                canEditAllPosts
              ) ||
              savingComment ||
              editingComment
            "
            (click)="editComment(comment)"
          >
            {{ translations.getTranslation$("edit") | async }}
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-menu-item
            [disabled]="
              !(
                comment.portalUserId === portalUserId ||
                comment.groupUserId === groupUserId ||
                canDeleteAnything
              ) || savingComment
            "
            (click)="deleteComment(comment)"
          >
            {{ translations.getTranslation$("delete") | async }}
            <mat-icon>delete</mat-icon>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>

<mat-card class="commentscard"> </mat-card>
