import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DateTime } from 'luxon';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  TimeInputDirective,
  TranslationService,
  ContextMenuAction,
  Day,
} from 'processdelight-angular-components';
import { combineLatest, Subject } from 'rxjs';
import { NotificationUserSetting } from 'src/app/core/domain/models/notification-user-setting.model';
import { ApplicationService } from 'src/app/core/services/application.service';
import { PortalService } from 'src/app/core/services/portal.service';

@Component({
  selector: 'app-notification-settings',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMaterialTimepickerModule,
    TimeInputDirective,
    MatProgressSpinnerModule,
  ],
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
})
export class NotificationSettingsComponent implements OnInit, OnDestroy {
  constructor(
    private ishtar: PortalService,
    public translations: TranslationService,
    private readonly applicationService: ApplicationService
  ) {}

  @Output() buttonActions = new EventEmitter<ContextMenuAction<unknown>[]>();

  isLoadingConfig = false;
  isIncomplete = false;

  config?: NotificationUserSetting;
  days: Day[] = [];
  selectedDays: string[] = [];

  dayTrackBy = (i: number, d: Day) => d.id;

  getName(d: any) {
    return d.category.name;
  }

  ngOnInit(): void {
    this.isLoadingConfig = true;
    combineLatest([
      this.ishtar.getDays(),
      this.ishtar.getNotificationConfig(
        this.applicationService.msal.accountInfo?.localAccountId
      ),
    ]).subscribe(([days, config]) => {
      this.days = days.filter((d) => d.abbreviation?.length === 3);
      this.isLoadingConfig = false;
      this.config = config;
      this.config.notificationConfigs = this.config.notificationConfigs.sort(
        (a, b) => a.category!.name.localeCompare(b.category!.name)
      );
      this.selectedDays = this.config.scheduledDisabledDays.map((d) => d.id);
      if (this.config.scheduledStartTime)
        this.config.scheduledStartTime = DateTime.fromFormat(
          this.config.scheduledStartTime,
          'HH:mm',
          {
            zone: 'utc',
          }
        )
          .toLocal()
          .toFormat('HH:mm');
      if (this.config.scheduledEndTime)
        this.config.scheduledEndTime = DateTime.fromFormat(
          this.config.scheduledEndTime,
          'HH:mm',
          {
            zone: 'utc',
          }
        )
          .toLocal()
          .toFormat('HH:mm');
    });

    this.buttonActions.next([
      new ContextMenuAction({
        icon: 'save',
        label: this.translations.getTranslation('save'),
        disabled: () => this.isLoadingConfig || this.isIncomplete,
        action: () => {
          this.save();
        },
      }),
    ]);
  }

  save() {
    if (!this.config) return;
    this.isLoadingConfig = true;
    this.ishtar
      .saveNotificationConfig(
        new NotificationUserSetting({
          ...this.config,
          scheduledDisabledDays: this.days.filter((d) =>
            this.selectedDays.includes(d.id)
          ),
          scheduledStartTime: this.config.scheduledStartTime
            ? DateTime.fromFormat(this.config.scheduledStartTime, 'HH:mm')
                .toUTC()
                .toFormat('HH:mm')
            : undefined,
          scheduledEndTime: this.config.scheduledEndTime
            ? DateTime.fromFormat(this.config.scheduledEndTime, 'HH:mm')
                .toUTC()
                .toFormat('HH:mm')
            : undefined,
        })
      )
      .subscribe(() => {
        this.isLoadingConfig = false;
      });
  }

  onChange() {
    if (!this.config) return;
    if (!this.config.scheduleTimeEnabled) {
      this.config.scheduledStartTime = undefined;
      this.config.scheduledEndTime = undefined;
      this.isIncomplete = false;
    } else {
      this.isIncomplete =
        !this.config.scheduledStartTime?.match(
          '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$'
        ) ||
        !this.config.scheduledEndTime?.match(
          '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$'
        );
    }
  }

  destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
