<ng-container [ngSwitch]="param.type">
  <ng-container *ngSwitchCase="ColumnType.Text">
    <mat-form-field [class]="class" appearance="outline" floatLabel="always">
      <mat-label>{{ labelOverride ?? param.title }}</mat-label>
      <input
        matInput
        type="text"
        [maxlength]="param.maxLength ?? 2000"
        [(ngModel)]="value"
        [required]="isRequired"
        [disabled]="disabled"
        [placeholder]="labelOverride ?? param.title"
        #input
      />
    </mat-form-field>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnType.Number">
    <mat-form-field [class]="class" appearance="outline" floatLabel="always">
      <mat-label>{{ labelOverride ?? param.title }}</mat-label>
      <input
        matInput
        type="number"
        [(ngModel)]="value"
        [required]="isRequired"
        [disabled]="disabled"
        [placeholder]="labelOverride ?? param.title"
        [max]="1000000000"
        #input
      />
    </mat-form-field>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnType.DateTime">
    <ng-container *ngIf="filter; else noFilterDate">
      <mat-form-field
        [class]="class"
        appearance="outline"
        floatLabel="always"
        *ngIf="isDateStartEnd"
      >
        <mat-label>{{ labelOverride ?? param.title }}</mat-label>
        <mat-date-range-input [rangePicker]="rangePicker">
          <input
            matStartDate
            [placeholder]="param.format ?? 'Start'"
            [(ngModel)]="dateStartEndStart"
            [disabled]="disabled"
            processdelightDatePickerFormat
            [datePickerFormat]="param.format ?? 'dd/MM/yyyy HH:mm'"
            #input
          />
          <input
            matEndDate
            [placeholder]="param.format ?? 'End'"
            [(ngModel)]="dateStartEndEnd"
            [disabled]="disabled"
            processdelightDatePickerFormat
            [datePickerFormat]="param.format ?? 'dd/MM/yyyy HH:mm'"
            #input2
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="rangePicker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #rangePicker></mat-date-range-picker>
      </mat-form-field>
    </ng-container>
    <ng-template #noFilterDate>
      <mat-form-field [class]="class" appearance="outline" floatLabel="always">
        <mat-label>{{ labelOverride ?? param.title }}</mat-label>
        <input
          matInput
          [(ngModel)]="value"
          [matDatepicker]="picker"
          [placeholder]="param.format ?? ''"
          [required]="isRequired"
          [disabled]="disabled"
          processdelightDatePickerFormat
          [datePickerFormat]="param.format ?? 'dd/MM/yyyy HH:mm'"
          #input
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnType.GroupUser">
    <mat-form-field
      [class]="class"
      appearance="outline"
      floatLabel="always"
      *ngIf="groupUsers$ | async as groupUsers"
    >
      <mat-label>{{ labelOverride ?? param.title }}</mat-label>
      <mat-select
        [(ngModel)]="value"
        [multiple]="!filter && param.multi"
        [required]="isRequired"
        [disabled]="disabled"
        [compareWith]="groupUserCompareFn"
        [placeholder]="labelOverride ?? param.title"
        panelClass=" p-0 metadata-dropdown"
        (opened)="focusElement(searchFilter)"
        #selectInput="matSelect"
      >
        <div class="d-flex flex-column h-100">
          <mat-form-field class="no-subscript-wrapper">
            <mat-label>{{ getTranslation$("search") | async }}...</mat-label>
            <input
              matInput
              type="text"
              #searchFilter
              (keydown.enter)="selectFirstGroupUser(searchFilter.value)"
            />
          </mat-form-field>
          <div class="overflow-auto scrollbar">
            <ng-container
              *ngIf="!filter && param.multi; else singleGroupUserValue"
            >
              <mat-option *ngFor="let v of selectedGroupUsers" [value]="v"
                >{{ v.displayName }}
              </mat-option>
            </ng-container>
            <ng-template #singleGroupUserValue>
              <mat-option *ngIf="value" [value]="value">
                {{ $any(value).displayName }}
              </mat-option>
            </ng-template>
            <mat-option
              [value]="undefined"
              *ngIf="filter || !param.required"
              (click)="!filter && param.multi ? reset() : undefined"
              >({{ getTranslation$("empty") | async }})</mat-option
            >
            <mat-option
              *ngFor="let groupUser of groupUsers"
              [value]="groupUser"
              [hidden]="
                (searchFilter.value &&
                  !groupUser.displayName
                    ?.toLowerCase()
                    ?.includes(searchFilter.value.toLowerCase())) ||
                (!filter && param.multi && selectedGroupUsers
                  ? selectedGroupUsers.includes(groupUser)
                  : false)
              "
              [title]="groupUser.user?.mail ?? groupUser.displayName"
            >
              {{ groupUser.displayName }}
            </mat-option>
          </div>
        </div>
      </mat-select>
    </mat-form-field>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnType.Choice">
    <div class="d-flex flex-column w-100 {{ class }}">
      <mat-form-field
        class="w-100 {{ class }}"
        appearance="outline"
        floatLabel="always"
        (click)="!disabled ? choiceMenuTrigger.openMenu() : undefined"
      >
        <mat-label>{{ labelOverride ?? param.title }}</mat-label>
        <mat-select
          [(ngModel)]="value"
          [multiple]="!filter && param.multi"
          [required]="isRequired"
          [disabled]="disabled"
          [placeholder]="labelOverride ?? param.title"
          panelClass="scrollbar p-0 metadata-dropdown"
          #choiceSelect="matSelect"
          (openedChange)="
            $event
              ? choiceMenuTrigger.openMenu()
              : choiceMenuTrigger.closeMenu()
          "
          #selectInput="matSelect"
        >
          <mat-select-trigger>
            {{ getSelectTrigger(params, param.id) }}
          </mat-select-trigger>
          <mat-option
            *ngFor="let choice of param.choices"
            [value]="choice.id"
            hidden
          >
          </mat-option>
        </mat-select>
        <button
          mat-icon-button
          matSuffix
          *ngIf="value && !disabled"
          (click)="value = undefined; $event.stopPropagation()"
        >
          <mat-icon class="material-icons-outlined"> cancel </mat-icon>
        </button>
      </mat-form-field>
      <div
        [matMenuTriggerFor]="choiceMenu"
        #choiceMenuTrigger="matMenuTrigger"
      ></div>
      <mat-menu
        #choiceMenu
        class="w-100 mw-100 overflow-hidden no-menu-padding"
        (closed)="choiceSelect.close()"
      >
        <div *ngIf="choiceMenuTrigger.menuOpen">
          <app-choice-select
            #appChoiceSelect
            [choiceParam]="param"
            [params]="params"
            [library]="library"
            [multiple]="!filter && param.multi"
            [filter]="filter"
            [value]="$any(value)"
            (valueChanges)="
              value = $event;
              !filter && param.multi ? undefined : choiceMenuTrigger.closeMenu()
            "
          ></app-choice-select>
        </div>
      </mat-menu>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="ColumnType.ConsolidatedChoice">
    <div class="d-flex flex-column w-100 {{ class }}">
      <mat-form-field
        class="w-100"
        appearance="outline"
        floatLabel="always"
        (click)="!disabled ? choiceMenuTrigger.openMenu() : undefined"
      >
        <mat-label>{{ labelOverride ?? param.title }}</mat-label>
        <mat-select
          [(ngModel)]="value"
          [multiple]="!filter && param.multi"
          [required]="isRequired"
          [disabled]="disabled"
          [placeholder]="labelOverride ?? param.title"
          panelClass="scrollbar p-0 metadata-dropdown"
          #choiceSelect="matSelect"
          #selectInput="matSelect"
          (opened)="choiceMenuTrigger.openMenu()"
        >
          <mat-select-trigger>
            {{ getConsolidatedSelectTrigger(params) }}
          </mat-select-trigger>
          <ng-container *ngFor="let cc of param.consolidatedChoices">
            <ng-container
              *ngIf="getChoiceParameter(params, cc.id) as choiceParam"
            >
              <mat-option
                *ngFor="let choice of choiceParam.choices"
                [value]="choiceParam.id + '.' + choice.id"
                hidden
              >
              </mat-option>
            </ng-container>
          </ng-container>
        </mat-select>
        <button
          mat-icon-button
          matSuffix
          *ngIf="value && !disabled"
          (click)="value = undefined; $event.stopPropagation()"
        >
          <mat-icon class="material-icons-outlined"> cancel </mat-icon>
        </button>
      </mat-form-field>
      <div
        [matMenuTriggerFor]="choiceMenu"
        #choiceMenuTrigger="matMenuTrigger"
      ></div>
      <mat-menu
        #choiceMenu
        class="w-100 mw-100 overflow-hidden no-menu-padding"
        (closed)="choiceSelect.close()"
      >
        <div *ngIf="choiceMenuTrigger.menuOpen">
          <app-choice-select
            #appChoiceSelect
            [choiceParam]="param"
            [params]="params"
            [library]="library"
            [multiple]="!filter && param.multi"
            [filter]="filter"
            [value]="$any(value)"
            (valueChanges)="
              value = $event;
              !filter && param.multi ? undefined : choiceMenuTrigger.closeMenu()
            "
          ></app-choice-select>
        </div>
      </mat-menu>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault></ng-container>
</ng-container>
