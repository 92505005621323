import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  ContextMenuAction,
  CRMEntity,
  DataSourceService,
  LoaderService,
  TranslationService,
} from 'processdelight-angular-components';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  of,
  Subject,
  timer,
} from 'rxjs';
import {
  catchError,
  delay,
  filter,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  navColor$,
  navContrast$,
  orgLogo$,
  userName$,
} from 'src/app/app.observables';
import { lastRouteKey } from 'src/app/core/guards/initial-navigation.guard';
import { OrganizationFacade } from 'src/app/core/store/organization/organization.facade';
import { UserFacade } from 'src/app/core/store/user/user.facade';
import { MicrosoftAuthenticationService } from 'src/app/msal/injectables/microsoft-authentication.service';
import { environment } from 'src/environments/environment';
import { IshtarOrganization } from '../domain/models/ishtar-organization.model';
import { getSubDomain, isB2C } from '../extensions/b2x.extensions';
import { AppFacade } from '../store/app/app.facade';
import { ClientFacade } from '../store/client/client.facade';
import { GroupFacade } from '../store/group/group.facade';
import { InterestGroupFacade } from '../store/interestGroup/interestGroup.facade';
import { PageFacade } from '../store/page/page.facade';
import { RoleFacade } from '../store/role/role.facade';
import { PortalActionsService } from './portal-actions.service';
import { PortalService } from './portal.service';
import { RouteBuilderService } from './route-builder.service';
import { SignupLinkService } from './signup-link.service';
import { PortalNotificationsService } from './portal-notifications.service';

export const LAST_CLIENT_ID = 'ISHTAR_PORTAL_LAST_CLIENT_ID';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService implements OnDestroy {
  signedIn = new BehaviorSubject<boolean>(false);

  destroy$ = new Subject<void>();

  constructor(
    public readonly msal: MicrosoftAuthenticationService,
    private readonly loader: LoaderService,
    private readonly userFacade: UserFacade,
    private readonly organizationFacade: OrganizationFacade,
    private readonly clientFacade: ClientFacade,
    private readonly roleFacade: RoleFacade,
    private readonly groupFacade: GroupFacade,
    private readonly pageFacade: PageFacade,
    private readonly appFacade: AppFacade,
    private readonly interestGroupFacade: InterestGroupFacade,
    private readonly routeBuilder: RouteBuilderService,
    private readonly translations: TranslationService,
    private readonly portalService: PortalService,
    private readonly portalActions: PortalActionsService,
    private readonly dataSourceService: DataSourceService,
    private readonly router: Router,
    private readonly linkService: SignupLinkService,
    private readonly snackbar: MatSnackBar,
    private readonly notificationService: PortalNotificationsService,
    private zone: NgZone
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async boot(): Promise<void> {
    this.addAuthentication();

    timer(15 * 60 * 1000, 15 * 60 * 1000)
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.signedIn.value)
      )
      .subscribe(() => this.portalService.sessionKeepAlive());
  }

  public resetApplication() {
    this.routeBuilder.resetRoutes();
    localStorage.removeItem(lastRouteKey);
    this.organizationFacade.resetSlice();
    this.userFacade.resetSlice();
  }

  public signIn() {
    this.msal.authenticate(getSubDomain());
  }
  public signOut() {
    this.msal.signOut();
  }

  // public goToAppSource(): void {
  //   window.open(this.appConfig.appSourceUrl, "_blank");
  // }

  private addAuthentication(): void {
    if (isB2C()) {
      this.msal.configure(
        {
          auth: {
            clientId: environment.b2cClientId,
            authority: `https://${environment.b2cTenantName}.b2clogin.com/${environment.b2cTenantName}.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN`,
            knownAuthorities: [`${environment.b2cTenantName}.b2clogin.com`],
            redirectUri: location.origin + '/signin',
            navigateToLoginRequestUrl: false,
            postLogoutRedirectUri: `${location.origin}/signout`,
          },
          cache: {
            cacheLocation: 'localStorage', // This configures where your cache will be stored
          },
          system: {
            allowRedirectInIframe: true,
          },
        },
        undefined,
        false
      );
    } else {
      this.msal.configure(
        {
          auth: {
            clientId: environment.clientId,
            authority: environment.authority,
            redirectUri: `${environment.ishtar365}/app-signin`,
            navigateToLoginRequestUrl: false,
            postLogoutRedirectUri: `${location.origin}/signout`,
          },
          cache: {
            cacheLocation: 'localStorage', // This configures where your cache will be stored
          },
          system: {
            allowRedirectInIframe: true,
          },
        },
        undefined,
        true
      );
    }
    this.msal.signedIn
      .pipe(takeUntil(this.destroy$))
      .subscribe((b) => this.signedIn.next(b));
    // if (isB2B()) this.msal.silentSignIn();
  }

  setCurrentClient(client: CRMEntity) {
    this.clientFacade.setCurrentClient(client);
    const clientId = client.id!;
    localStorage.setItem(LAST_CLIENT_ID, clientId);
  }

  initTranslations() {
    this.userFacade.userLicenseInfo$
      .pipe(
        switchMap((user) => {
          this.translations.setLocale(user?.language ?? 'en');
          return this.portalService.getTranslations(user?.language ?? 'en');
        }),
        tap((translations) => this.translations.update(translations))
      )
      .subscribe();
  }

  setupGlobalObservers() {
    this.organizationFacade.organizationInfo$
      .pipe(map((o) => o?.logo))
      .subscribe((v) => {
        orgLogo$.next(v);
      });

    this.userFacade.userLicenseInfo$
      .pipe(map((u) => u?.name))
      .subscribe((v) => {
        userName$.next(v);
      });
    //userPhoto$ = this.userFacade.userLicenses$.pipe(map((u) => u?.photo));

    this.organizationFacade.organizationInfo$
      .pipe(map((p) => p?.navColor))
      .subscribe((v) => {
        navColor$.next(v);
      });
    this.organizationFacade.organizationInfo$
      .pipe(map((p) => p?.navContrast))
      .subscribe((v) => {
        navContrast$.next(v);
      });
  }

  public initAppData() {
    const subDomain = getSubDomain();
    this.setupGlobalObservers();
    const initPipe = of(true).pipe(
      tap(() => {
        this.pageFacade.getWebPartTypes$().subscribe();
        this.organizationFacade.getGroupUsers();
        this.dataSourceService.updateDataWithObservable(
          'peopleAndGroups',
          this.organizationFacade.groupUsers$
        );

        this.dataSourceService.updateDataWithObservable(
          'people',
          this.organizationFacade.users$
        );

        this.dataSourceService.updateDataWithObservable(
          'groups',
          this.organizationFacade.groups$
        );

        this.dataSourceService.updateDataWithObservable(
          'relationFieldTables',
          this.portalService.getRelationFieldTables()
        );

        this.dataSourceService.updateDataWithObservable(
          'countries',
          this.portalService.getCountries()
        );
        this.dataSourceService.updateDataWithObservable(
          'interestGroups',
          this.interestGroupFacade.getInterestGroups$()
        );
      }),
      switchMap(() =>
        forkJoin([
          this.clientFacade.getClients$(),
          this.pageFacade.getPages$(),
          this.roleFacade.getRoles$(),
          this.roleFacade.getDefaultRoles$(),
        ])
      )
    );
    const pipe = of(true).pipe(
      delay(10),
      tap(() => this.initTranslations()),
      switchMap(() => this.routeBuilder.initRoutes()),
      switchMap(() =>
        this.userFacade.isAdmin$().pipe(
          tap((isAdmin) =>
            isAdmin
              ? this.portalActions.iconActions.push(
                  new ContextMenuAction<unknown>({
                    label: 'Settings',
                    icon: 'settings',
                    action: () =>
                      this.router.navigate(['admin/settings', 'roles']),
                  })
                )
              : null
          )
        )
      )
    );

    if (isB2C() && subDomain) {
      return this.portalService.registerExternalSession(subDomain).pipe(
        switchMap((userInfo) => {
          if (userInfo) {
            this.userFacade.getExternalUserInfoResolved(userInfo);
            this.organizationFacade.getOrganizationInfoResolved(
              userInfo.organization
            );
          }

          this.notificationService.Init();

          const signupId = this.routeBuilder.signupLink();
          if (signupId) {
            if (
              userInfo &&
              (userInfo.clientUserRequests.some(
                (r) => r.client.id === signupId
              ) ||
                userInfo.clientUserInfo.some((c) => c.client.id === signupId))
            )
              this.openSnackbar(
                this.translations.getTranslation('alreadyJoinedOrg')
              );
            else
              return this.clientFacade
                .newRequest$(
                  new CRMEntity({
                    id: signupId,
                    name: 'Invite link',
                  })
                )
                .pipe(
                  tap(() =>
                    this.routeBuilder.initSpecialRoute(
                      'wait-for-approval',
                      true
                    )
                  ),
                  tap(() =>
                    this.openSnackbar(
                      this.translations.getTranslation(
                        'requestCreatedSuccessful'
                      )
                    )
                  ),
                  catchError(() => {
                    this.openSnackbar(
                      this.translations.getTranslation(
                        'errorSomethingWentWrong'
                      )
                    );
                    return of(null);
                  })
                );
          }
          if (
            userInfo == null ||
            (!userInfo.clientUserInfo.length &&
              userInfo.clientUserRequests.length)
          )
            return of(null).pipe(
              tap(() =>
                this.routeBuilder.initSpecialRoute('wait-for-approval', true)
              )
            );
          else
            return initPipe.pipe(
              switchMap(() => {
                if (userInfo.clientUserInfo.length) {
                  const lastClientId = localStorage.getItem(LAST_CLIENT_ID);
                  let client = userInfo.clientUserInfo.find(
                    (c) => c.client.id === lastClientId
                  )?.client;
                  if (!client) client = userInfo.clientUserInfo[0].client;
                  this.interestGroupFacade
                    .getInterestGroupsForUser$(userInfo.portalUserId!)
                    .subscribe();
                  this.setCurrentClient(client);
                  return of(true);
                } else {
                  throw new Error('User has no clients assigned');
                }
              }),
              switchMap(() => pipe),
              catchError((err: Error) => {
                if (err.message === 'User has no clients assigned') {
                  return of(null);
                }
                throw err;
              })
            );
        })
      );
    } else {
      let tenantId = new URLSearchParams(location.search).get('tenantId');
      if (!tenantId) {
        const lastRoute = localStorage.getItem(lastRouteKey);
        if (lastRoute) {
          tenantId = new URLSearchParams(
            new URL(location.origin + lastRoute).search
          ).get('tenantId');
        }
        if (!tenantId) throw new Error('No tenantId found in URL');
      }
      return this.portalService.registerSession(tenantId).pipe(
        tap((licenseInfo) => {
          this.userFacade.getUserLicenseInfoResolved(licenseInfo);
          this.organizationFacade.getOrganizationInfoResolved(
            new IshtarOrganization({
              tenantId: licenseInfo.tenantId,
              sharepointUrl: licenseInfo.sharepointUrl,
              name: licenseInfo.organization,
              navColor: licenseInfo.navColor,
              navContrast: licenseInfo.navContrast,
              dataverseEnvironmentUrl: licenseInfo.dataverseEnvironmentUrl,
              domain: licenseInfo.domain,
            })
          );
        }),
        switchMap(() => initPipe),
        tap(() => {
          this.appFacade.getAppConfig$().subscribe();
          //this.organizationFacade.getPortalUsers$().subscribe(); not used
          this.clientFacade.getClientUserRequests$().subscribe();
        }),
        switchMap(() => pipe)
      );
    }
  }

  public openSnackbar(message: string, action = 'X', duration = 4000): void {
    this.zone.run(() => {
      this.snackbar.open(message, action, { duration });
    });
  }
}
