<div class="webPartContainer d-flex flex-column w-100 h-100">
  <ng-container
    *ngIf="getTranslation$('filteredGroups') | async as filteredGroupsLabel"
  >
    <h5
      *ngIf="_webpartViewbox?.height! > 1 && _webpartViewbox?.width! > 1"
      class="mx-auto"
    >
      {{ filteredGroupsLabel }}
    </h5>
  </ng-container>
  <div class="scrollbar w-100 h-100 overflow-auto min-height-0">

    <mat-form-field
      class="search_input_wrapper"
      appearance="outline"
      floatLabel="always">
      <mat-label>
        {{ getTranslation$('search') | async }}
        {{ getTranslation$('groups') | async }}
      </mat-label>
      <input
        matInput
        (keydown)="$event.stopPropagation()" 
        (input)="filterGroups($event)"
      />
    </mat-form-field>

    <!-- no forced group selected and viewbox is > 1 -->
    <mat-tree
      *ngIf="
        !forcedGroup &&
        _webpartViewbox?.height! > 1 &&
        _webpartViewbox?.width! > 1
      "
      [dataSource]="dataSource"
      [treeControl]="treeControl"
      class="example-tree w-100 h-100"
    >
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <div class="d-flex flex-row align-items-center w-100 h-100">
          <mat-checkbox
            (change)="checkBoxChange($event, node)"
            [checked]="node.isSelected"
          >
          </mat-checkbox>
          <mat-card
            [style.borderColor]="node.color"
            class="position-relative w-100 d-flex flex-column justify-content-center"
          >
            <div
              class="position-absolute start-0 top-0 h-100"
              style="width: 5px"
              [style.backgroundColor]="node.color"
            ></div>
            <div class="d-flex align-items-center w-100">
              <ng-container *ngIf="node.icon">
                <img
                  class="list-icon m-2"
                  [src]="transform(node.icon)"
                  alt="Interest group icon"
                />
              </ng-container>
              <div class="d-flex flex-column ps-3">
                <div class="bold">{{ node.title }}</div>
                <div class="italic">{{ node.description }}</div>
              </div>
            </div>
          </mat-card>
        </div>
      </mat-tree-node>
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node">
          <div class="d-flex flex-row align-items-center w-100 h-100">
            <button
              mat-icon-button
              matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + node.name"
            >
              <mat-icon class="mat-icon-rtl-mirror">
                {{
                  treeControl.isExpanded(node) ? "expand_more" : "chevron_right"
                }}
              </mat-icon>
            </button>
            <mat-checkbox
              (change)="checkBoxChange($event, node)"
              [checked]="node.isSelected"
            >
            </mat-checkbox>
            <mat-card
              [style.borderColor]="node.color"
              class="position-relative w-100 d-flex flex-column justify-content-center"
            >
              <div
                class="position-absolute start-0 top-0 h-100"
                style="width: 5px"
                [style.backgroundColor]="node.color"
              ></div>
              <div class="d-flex align-items-center w-100">
                <ng-container *ngIf="node.icon">
                  <img
                    class="list-icon m-2"
                    [src]="transform(node.icon)"
                    alt="Interest group icon"
                  />
                </ng-container>
                <div class="d-flex flex-column ps-3">
                  <div class="bold">{{ node.title }}</div>
                  <div class="italic">{{ node.description }}</div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
        <div
          [class.example-tree-invisible]="!treeControl.isExpanded(node)"
          role="group"
        >
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
    <!-- forced group -->
    <mat-card
      *ngIf="forcedGroup"
      class="position-relative d-flex flex-column justify-content-center"
      [style.borderColor]="forcedGroup.color"
    >
      <div
        class="position-absolute start-0 top-0 h-100"
        style="width: 5px"
        [style.backgroundColor]="forcedGroup.color"
      ></div>
      <div class="d-flex align-items-center w-100">
        <div class="d-flex flex-column ps-3">
          <div class="bold">{{ forcedGroup.title }}</div>
          <div class="italic">{{ forcedGroup.description }}</div>
        </div>
        <ng-container *ngIf="forcedGroup.icon">
          <img
            class="list-icon m-2"
            [src]="transform(forcedGroup.icon)"
            alt="Interest group icon"
          />
        </ng-container>
      </div>
    </mat-card>
    <!-- no forced group selected and viewbox is 1 -->
    <div
      class="h-100 w-100 d-flex flex-column align-items-center min-height-0"
      [ngClass]="_webpartViewbox?.width === 1 ? '' : 'justify-content-evenly'"
      *ngIf="
        !forcedGroup &&
        (_webpartViewbox?.height === 1 || _webpartViewbox?.width === 1)
      "
    >
      <button
        (click)="openFilterDialog()"
        class="m-1"
        mat-mini-fab
        color="primary"
      >
        <mat-icon>filter_list</mat-icon>
      </button>
      <mat-chip-set
        *ngIf="filteredGroups.length > 0; else noFilteredGroups"
        [ngClass]="
          _webpartViewbox?.width === 1 ? 'mat-mdc-chip-set-stacked' : ''
        "
        class="scrollbar overflow-auto"
      >
        <mat-chip
          [ngStyle]="
            _webpartViewbox?.width === 1
              ? { 'margin-left': '18px', 'margin-right': '10px' }
              : {}
          "
          [style.backgroundColor]="group.color + ' !important'"
          *ngFor="let group of filteredGroups"
          ><span
            class="textOverflow"
            [style.color]="getTextColor(group.color)"
            >{{ group.title }}</span
          ></mat-chip
        >
      </mat-chip-set>
      <ng-template #noFilteredGroups>
        <span
          *ngIf="
            getTranslation$('noGroupsSelected') | async as noGroupsSelectedLabel
          "
          >{{ noGroupsSelectedLabel }}</span
        >
      </ng-template>
    </div>
  </div>
</div>
