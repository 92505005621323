import { Action, createReducer, on } from '@ngrx/store';
import { CMSPage, WebPartType } from 'processdelight-angular-components';
import {
  getPages,
  getPagesResolved,
  getWebPartTypes,
  getWebPartTypesResolved,
  resetSlice,
} from './page.actions';

export const featureSlice = 'page';

export interface State {
  loadingPages: boolean;
  loadingWebPartTypes: boolean;
  pages: CMSPage[];
  webPartTypes: WebPartType[];
}

const defaultState: State = {
  loadingPages: false,
  loadingWebPartTypes: false,
  pages: [],
  webPartTypes: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return pageReducer(state, action);
}

export const initialState: State = defaultState;
export const pageReducer = createReducer(
  initialState,
  on(getPages, (state) => ({ ...state, loadingPages: true })),
  on(getPagesResolved, (state, { pages }) => ({
    ...state,
    pages: [...pages],
    loadingPages: false,
  })),
  on(getWebPartTypes, (state) => ({ ...state, loadingWebPartTypes: true })),
  on(getWebPartTypesResolved, (state, { webPartTypes }) => ({
    ...state,
    webPartTypes,
    loadingWebPartTypes: false,
  })),
  on(resetSlice, () => initialState)
);
