<div class="d-flex flex-column w-100 p-3">
  <h2 class="ps-3 pt-3">{{ getTranslation$("setUpPrefilter") | async }}</h2>
  <div class="d-flex flex-column w-100 p-3">
    <div class="d-flex flex-row w-100 align-items-center mb-3">
      <mat-form-field appearance="outline" class="ms-2 me-2 no-subscript-wrapper">
        <mat-label>{{ getTranslation$("selectTemplate") | async }}</mat-label>
        <mat-select [formControl]="selectedTemplateControl">
          <mat-option
            *ngFor="let template of formTemplates$ | async"
            [value]="template"
          >
            {{ template.form?.name }}
          </mat-option>
        </mat-select>
        <mat-spinner
          matSuffix
          *ngIf="loadingFormTemplates"
          class="m-1"
          diameter="26"
        ></mat-spinner>
        <button
          mat-icon-button
          matSuffix
          *ngIf="selectedTemplateControl.value"
          (click)="clearTemplateSelection($event)"
          class="cancel-button"
        >
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
</div>
