import {
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Subject, takeUntil, finalize, tap, map } from 'rxjs';
import * as uuid from 'uuid';
import {
  AbstractWebPartConfigComponent,
  EntityFacade,
  EntityObject,
  StateEntity,
  WebPartConfig,
} from 'processdelight-angular-components';
import { TranslationService } from 'processdelight-angular-components';
import { Task } from '../domain/models/task.model';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-tasks-config',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule
  ],
  templateUrl: './tasks-config.component.html',
  styleUrls: ['./tasks-config.component.scss'],
})
export class TasksConfigComponent
  extends AbstractWebPartConfigComponent
  implements OnInit, OnDestroy
{
  private entityFacade: EntityFacade = inject(EntityFacade);
  private translationService: TranslationService = inject(TranslationService);

  loadingFormTemplates = true;
  formTemplates$ = this.entityFacade.getFormTemplates$<Task>(StateEntity.Task)
    .pipe(
      map((templates) =>
        [...templates].sort((a, b) => (a.form?.name ?? '').localeCompare(b.form?.name ?? ''))
      ),
      tap((templates) => {
        this.setInitialTemplateSelection(templates);
      }),
      finalize(() => {
        this.loadingFormTemplates = false;
      })
  );
  selectedTemplateControl = new FormControl<EntityObject<Task> | undefined>(undefined);

  emitChanges() {
    const templateConfig = this.configurations?.find((c) => c.name === 'template') 
      ?? new WebPartConfig({
        id: uuid.v4(),
        name: 'template',
        configuration: '',
      });
    this.valueChanges.emit([
      new WebPartConfig({
        id: templateConfig.id,
        name: templateConfig.name,
        configuration: this.selectedTemplateControl.value?.formId ?? '',
      }),
    ]);
  }

  getTranslation(label: string): string {
    return this.translationService.getTranslation(label);
  }

  getTranslation$(label: string) {
    return this.translationService.getTranslation$(label);
  }

  @Input() formTemplate?: EntityObject<Task>;

  destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.selectedTemplateControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((template) => {
        this.formTemplate = template ?? undefined;
        this.emitChanges();
      });
  }

  setInitialTemplateSelection(templates: EntityObject<Task>[]) {
    const templateConfig = this.configurations?.find(
      (c) => c.name === 'template'
    );

    if (templateConfig?.configuration) {
      const selectedTemplate = templates.find(
        (template) => template.id === templateConfig.configuration
      );
      this.selectedTemplateControl.setValue(selectedTemplate, {
        emitEvent: false,
      });
    }
  }

  clearTemplateSelection(event: Event) {
    event.stopPropagation();
    this.selectedTemplateControl.reset();
    this.emitChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
