import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

export const lastRouteKey = 'Ishtar.Portal_Last_Route';

@Injectable({
  providedIn: 'root',
})
export class InitialNavigationGuard implements CanActivate {
  ignorePaths = ['signin', 'wait-for-approval'];
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.ignorePaths.some((path) => location.pathname.includes(path)))
      localStorage.setItem(lastRouteKey, location.pathname + location.search);
    return true;
  }
}
